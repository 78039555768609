// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as FormTagsScss from "./FormTags.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = FormTagsScss;

function FormTags(props) {
  var updateTags = props.updateTags;
  return JsxRuntime.jsx("div", {
              children: Belt_Array.mapWithIndex(props.tags, (function (index, tag) {
                      return JsxRuntime.jsx("div", {
                                  children: tag + " x",
                                  className: css.tag,
                                  onClick: updateTags(tag)
                                }, String(index));
                    })),
              className: css.tags
            });
}

var make = FormTags;

export {
  css ,
  make ,
}
/* css Not a pure module */
